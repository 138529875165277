import _ from 'lodash'
import { PlatformModel, SiteAssetsResourceType, PlatformLogger, AppStructureWithFeatures, PageFeaturesConfig } from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import { BootstrapData } from '../types'
import { MasterPageId } from './constants'
import { errorPagesIds } from '@wix/thunderbolt-commons'

type FeaturesResponse = {
	props: PageFeaturesConfig
	structure: AppStructureWithFeatures
}

type RawModel = {
	structureModel: AppStructureWithFeatures['components']
	propsModel: PageFeaturesConfig['render']['compProps']
	platformModel: PlatformModel
	pageConfig: PageFeaturesConfig
}

type ModelAPI = {
	getCompIdByWixCodeNickname: (nickname: string) => string
	getPageIdByCompId: (compId: string) => string
	getControllerTypeByCompId: (compId: string) => string | undefined
	getParentId: (compId: string) => string | undefined
	getCompType: (compId: string) => string | undefined
}

export type Model = RawModel & ModelAPI

export default function({ bootstrapData, logger, siteAssetsClient, handlers }: { bootstrapData: BootstrapData; logger: PlatformLogger; siteAssetsClient: SiteAssetsClientAdapter; handlers: any }) {
	const fetchModel = <T>(resourceType: SiteAssetsResourceType, isMasterPage: boolean): Promise<T> =>
		logger.runAsyncAndReport(`getModel_${resourceType}${isMasterPage ? `_${MasterPageId}` : ''}`, () => {
			const pageCompId = isMasterPage ? MasterPageId : `${bootstrapData.currentPageId}`
			const isErrorPage = !!errorPagesIds[pageCompId]

			const pageJsonFileNames = bootstrapData.siteAssetsClientInitParams.siteScopeParams.pageJsonFileNames
			const pageJsonFileName = isMasterPage || isErrorPage ? pageJsonFileNames[MasterPageId] : pageJsonFileNames[pageCompId]
			// TODO - handle/catch site-assets client error
			return siteAssetsClient.execute(
				{
					moduleParams: bootstrapData.siteAssetsClientInitParams.modulesParams[resourceType],
					pageCompId,
					...(isErrorPage ? { pageCompId: isErrorPage ? 'masterPage' : pageCompId, errorPageId: pageCompId } : {}),
					pageJsonFileName: pageJsonFileName || bootstrapData.pageJsonFileName
				},
				'disable'
			)
		})

	function mergeConnections(masterPageConnections: PlatformModel['connections'], pageConnections: PlatformModel['connections']) {
		// merge connection arrays
		return _.mergeWith(pageConnections, masterPageConnections, (objValue, srcValue) => (_.isArray(objValue) ? objValue.concat(srcValue) : undefined))
	}

	const getModelFromSiteAssetsResponses = ([platformModel, featuresModel]: [PlatformModel, FeaturesResponse]) => {
		const {
			props: pageConfig,
			structure: { components: structureModel }
		} = featuresModel
		const { connections, applications, orderedControllers, onLoadProperties } = platformModel
		const propsModel = pageConfig.render.compProps

		return {
			pageConfig,
			propsModel,
			structureModel,
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData: platformModel.sdkData,
				staticEvents: platformModel.staticEvents,
				compIdConnections: platformModel.compIdConnections,
				containersChildrenIds: platformModel.containersChildrenIds,
				onLoadProperties
			}
		}
	}

	const getRawModel = async () => {
		const pageModelPromise = Promise.all([fetchModel<PlatformModel>('platform', false), fetchModel<FeaturesResponse>('features', false)]).then(getModelFromSiteAssetsResponses)
		if (bootstrapData.platformEnvData.bi.pageData.isLightbox) {
			return pageModelPromise
		}

		const masterPageModelPromise = Promise.all([fetchModel<PlatformModel>('platform', true), fetchModel<FeaturesResponse>('features', true)]).then(getModelFromSiteAssetsResponses)
		const [pageModel, masterPageModel] = await Promise.all([pageModelPromise, masterPageModelPromise])

		const pageConfig = _.merge({}, masterPageModel.pageConfig, pageModel.pageConfig)
		const connections = mergeConnections(masterPageModel.platformModel.connections, pageModel.platformModel.connections)
		const onLoadProperties = _.merge({}, masterPageModel.platformModel.onLoadProperties, pageModel.platformModel.onLoadProperties)
		const structureModel = _.assign({}, masterPageModel.structureModel, pageModel.structureModel)
		const applications = _.merge({}, masterPageModel.platformModel.applications, pageModel.platformModel.applications)
		const sdkData = _.assign({}, masterPageModel.platformModel.sdkData, pageModel.platformModel.sdkData)
		const staticEvents = _.concat(masterPageModel.platformModel.staticEvents, pageModel.platformModel.staticEvents)
		const compIdConnections = _.assign({}, masterPageModel.platformModel.compIdConnections, pageModel.platformModel.compIdConnections)
		const containersChildrenIds = _.assign({}, masterPageModel.platformModel.containersChildrenIds, pageModel.platformModel.containersChildrenIds)
		const orderedControllers = masterPageModel.platformModel.orderedControllers.concat(pageModel.platformModel.orderedControllers)
		const propsModel = pageConfig.render.compProps

		return {
			pageConfig,
			propsModel,
			structureModel,
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData,
				staticEvents,
				compIdConnections,
				containersChildrenIds,
				onLoadProperties
			}
		}
	}

	const getAPIsOverModel = (model: RawModel) => {
		const getPageIdByCompId = (compId: string) => (model.structureModel[compId] ? bootstrapData.currentPageId : MasterPageId)
		const getCompIdByWixCodeNickname = (nickname: string) => _.get(model.platformModel.connections, ['wixCode', nickname, 0, 'compId'])
		const getParentId = (compId: string) => _.findKey(model.structureModel, ({ components }) => components && components.includes(compId))
		const getCompType = (compId: string) => model.structureModel[compId].componentType
		const getControllerTypeByCompId = (compId: string) => {
			const appControllers = _.find(model.platformModel.applications, (controllers) => !!controllers[compId])
			return _.get(appControllers, [compId, 'controllerType'], '')
		}

		return {
			getPageIdByCompId,
			getControllerTypeByCompId,
			getCompIdByWixCodeNickname,
			getParentId,
			getCompType
		}
	}

	async function getModel(): Promise<Model> {
		const model = await getRawModel()
		model.platformModel.orderedControllers = ['wixCode', ...model.platformModel.orderedControllers]

		if (!bootstrapData.platformEnvData.window.isSSR) {
			handlers.registerOnPropsChangedHandler(bootstrapData.currentPageId, (changes: { [compId: string]: any }) => {
				_.map(changes, (newProps: any, compId: string) => {
					_.assign(model.propsModel[compId], newProps)
				})
			})
		}

		return {
			...model,
			...getAPIsOverModel(model)
		}
	}

	return {
		getModel
	}
}
