import { SiteAssetsClientAdapterFactoryImpl } from './siteAssetsClientAdapter'
import {
	DataFixersParams,
	Experiments,
	FetchFn,
	SiteAssetsClientTopology,
	SiteAssetsManifests,
	SiteScopeParams,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import {
	FallbackStrategy,
	SiteAssetsClientConfig,
	SiteAssetsMetricsReporter,
	SiteAssetsModuleFetcher,
	SiteAssetsTopology,
} from 'site-assets-client'

import { SiteAssetsClientAdapter } from './types'

type ClientSiteAssetsClientAdapterFactoryParams = {
	fetchFn: FetchFn
	clientTopology: SiteAssetsClientTopology
	siteAssetsMetricsReporter: SiteAssetsMetricsReporter
	manifests: SiteAssetsManifests
	timeout: number
	dataFixersParams: DataFixersParams
	requestUrl: string
	siteScopeParams: SiteScopeParams
	moduleFetcher: SiteAssetsModuleFetcher
	isStagingRequest?: boolean
	fallbackOverride: FallbackStrategy | undefined
	beckyExperiments: Experiments
	staticHTMLComponentUrl: string
	remoteWidgetStructureBuilderVersion: string
	deviceInfo: ViewerModel['deviceInfo']
	qaMode?: boolean
}

const toSiteAssetsTopology = (clientTopology: SiteAssetsClientTopology): SiteAssetsTopology => {
	const {
		mediaRootUrl,
		staticMediaUrl,
		htmlComponentsDomainUrl,
		siteAssetsUrl,
		moduleRepoUrl,
		fileRepoUrl,
	} = clientTopology

	return {
		mediaRootUrl,
		staticMediaUrl,
		htmlComponentsDomainUrl,
		siteAssetsServerUrl: siteAssetsUrl,
		moduleRepoUrl,
		fileRepoUrl,
	}
}

export const createClientSiteAssetsClientFactory = ({
	fetchFn,
	clientTopology,
	siteAssetsMetricsReporter,
	manifests,
	timeout,
	dataFixersParams,
	requestUrl,
	siteScopeParams,
	moduleFetcher,
	isStagingRequest,
	fallbackOverride,
	beckyExperiments,
	staticHTMLComponentUrl,
	remoteWidgetStructureBuilderVersion,
	deviceInfo,
	qaMode,
}: ClientSiteAssetsClientAdapterFactoryParams): SiteAssetsClientAdapter => {
	const topology = toSiteAssetsTopology(clientTopology)
	const config: SiteAssetsClientConfig = {
		moduleTopology: {
			publicEnvironment: topology,
			environment: topology,
		},
		staticsTopology: {
			timeout,
			baseURLs: clientTopology.pageJsonServerUrls,
		},
		isStagingRequest,
	}

	return SiteAssetsClientAdapterFactoryImpl({
		fetchFn,
		config,
		siteAssetsMetricsReporter,
		manifests,
		moduleFetcher,
	})({
		dataFixersParams,
		requestUrl,
		siteScopeParams,
		fallbackOverride,
		beckyExperiments,
		staticHTMLComponentUrl,
		remoteWidgetStructureBuilderVersion,
		deviceInfo,
		qaMode,
	})
}
